export var isExcludedNode = function (node, excluded) {
    return excluded.some(function (exclude) {
        return node.matches("".concat(exclude, ", .").concat(exclude, ", ").concat(exclude, " *, .").concat(exclude, " *"));
    });
};
export var cancelTimeout = function (timeout) {
    if (timeout) {
        clearTimeout(timeout);
    }
};
